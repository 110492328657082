<template>
	<div class="wrapper">
		<!-- 页面头部部分 -->
		<div class="header flex" style="justify-content: space-around;">
			<div class="logo f_left">
				<img src="@/assets/imgs/logo2.png" alt="" />
			</div>

			<div class="menu">
				<a-menu mode="horizontal" :selectedKeys="navOn">
					<a-menu-item v-for="(item, index) in items"	:key="index" @click="two(item.url)">
						<!-- <router-link :to="url +'/'+ item.url"></router-link> -->
						<span class="navTitle">{{ item.title }}</span>
					</a-menu-item>
				</a-menu>
			</div>
			<div class="hright">
				<div class="header-right flex">
					<div class="font">
						<span>大字体</span><a-switch checked-children="开" un-checked-children="关" v-model="fontsizeCheck"/>
					</div>
				</div>
				<div>
					<img :src="userImgUrl" alt=""><span>{{userName}}</span>
				</div>
			</div>
		</div>
		<div style="height: 60px"></div>
		<!-- 页面左侧二级菜单栏，和主体内容区域部分 -->
		<div class="sidebar" v-if="current.children">
			<!-- 左侧二级菜单栏的组件封装 -->
			<a-menu  :selectedKeys="subNavOn">
				<a-menu-item v-for="(item, index) in current.children" :key="index">
					<!-- 需要图标的在 item 对象中加上属性 icon -->
					<!-- <i :class="item.icon"></i> -->
					<!-- {{url}}{{fullPath}}{{item.path}} -->
					<router-link :to="url + '/' + fullPath + '/' + item.path">{{item.meta.title}}</router-link>
				</a-menu-item>
			</a-menu>
		</div>
		<router-view></router-view>
	</div>
</template>


<script>
import $ from "jquery";
// import APP from "@/App";
import { requestXml } from "../../assets/js/request";
export default {
	data() {
		return {
			url: "/scrm_pc",
			items: [],
			// current: APP.data().current,
			// fullPath: APP.data().fullPath,
			// navOn: APP.data().navOn,
			current: [],
			fullPath: "",
			navOn:[],
			subNavOn:[],
			fontsizeCheck:false,

			userImgUrl: "",
			userName: ""
		};
	},
	mounted: function () {
		this.getUserInfo();
		this.url = this.$root.baseUrl;
		// this.$nextTick(function () {
		//     this.mathed();
		//     this.url = this.$root.baseUrl;
		// });
		requestXml("/scrm/index/getMenu", "GET", (res) => {
			this.items = res;
			let path=this.$route.path.split('/')[2];
			let currentMenu = this.items.find((item) => item.url == path);
			// console.log(path,currentMenu);

			this.getNavIndex(path);
			this.getsubMenu(currentMenu.id,currentMenu.url);
		});
	},
	watch: {
		$route(val) {
			let parme = val.path.split("/")[2];
			let currentMenu = this.items.find((item) => item.url == parme);
			this.getNavIndex(parme);
			this.getsubMenu(currentMenu.id,currentMenu.url);
		},
		fontsizeCheck(val){
			if(val){
				$("#app").css('fontSize','18px')
			}else{
				$("#app").css('fontSize','16px')
			}
			// console.log('大字体',$("#app").css('fontSize','18px'));
		}
	},
	methods: {
		getUserInfo(){
			requestXml("/scrm/index/getUserInfo","GET",(res) => {
				// console.log(res)
				this.userImgUrl = res.avatar;
				this.userName = res.name;
			})
		},
		// mathed: function (val = this.$route) {
		//     this.fullPath = val.fullPath.split('/')[2];
		//     let route = this.$router?.options["routes"][0]?.children;
		//     console.log(route,"route")
		//     let current = route.find(
		//         (item) => item.path == val.path.split("/")[2]
		//     );
		//     console.log("current",current,val.path.split("/")[2])
		//     if(current){
		//         this.current = current;
		//     }else{
		//         this.current = "";
		//     }
		// },
		two(path) {
			console.log(path)
			this.$router.push({
				path: this.url + "/" + path,
			});
		},
		//获取二级菜单 parmes：一级菜单id
		getsubMenu(parmes,path) {
			let that=this;
			this.fullPath = path;
			let subPath=this.$route.path.split("/")[3];
			requestXml(
				"/scrm/index/getMenu",
				"GET",
				(res) => {
					// console.log(res);
					if (res.length == 0) {
						this.current = "";
					} else {
						let route = this.$router?.options["routes"][1]?.children;

						let current = route.find((item) => item.path == path);
						let current2 = [];
							res.map((el) => {
								current.children.map((e, i) => {
								if (el.url==e.path) {
										current.children[i].meta.title=el.title;
										current2.push(current.children[i]);
									}
							})
						})
						current2.map((el, index)=>{
							if (el.path==subPath) {
								that.subNavOn=[index];
							}
						})
						// current.children.map((el, index) => {
						//   if (res.find(item => item.url == el.path)) {
						//     current2.push(current.children[index])
						//   }
						// })
						// console.log(current);
						current.children = current2;
						this.current = current;
					}
				},
				{ pid: parmes }
			);
		},
		getNavIndex(parme){
			let res=this.items;
			for(let i=0;i<res.length;i++){
				if(res[i].url==parme){
					this.navOn=[i];
					break;
				}
			}
		},
	},
	
	
};
</script>
<style scoped>
.header {
	width: 100%;
	height: 59px;
	background-color: #36a3ff;
	color: #fff;
	position: fixed;
	z-index: 99;
}
.header .logo {
	width: 170px;
	height: 25px;
	margin: 10px 0;
}
.header .menu {
	max-width: 800px;
	/* margin-left: 280px; */
}
.header .menu .ant-menu {
	height: 60px;
	line-height: 58px;
	font-weight: 700;
}
.header .hright{
	width: 250px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.header .hright img{
	width: 40px;
	border-radius: 50%;
	margin-right: 10px;
}
.header .header-right {
	width: 120px;
	/* height: 100%;
	position: absolute;
	top: 0;
	right: 50px; */
	justify-content: space-between;
	align-items: center;
}
.header .header-right .news img {
	width: 20px;
	height: 20px;
}
.header .header-right .news span {
	display: inline-block;
	margin-left: 13px;
}
.header .header-right .font span {
	display: inline-block;
	margin-right: 13px;
}
.navTitle{
	user-select: none;
}
.menu .ant-menu-horizontal > .ant-menu-item-selected {
	background: #fff;
}
.menu .ant-menu-horizontal > .ant-menu-item-selected span {
	color: #333;
}

/* 左侧菜单栏定位和位置大小设定 */
.sidebar {
	width: 200px;
	height: calc(100vh - 60px);
	padding: 0 4px;
	display: block;
	position: fixed;
	left: 0;
	top: 60px;
	bottom: 0;
	background-color: #fff;
}
.sidebar::-webkit-scrollbar {
	width: 0;
}
.sidebar .ant-menu {
	width: 100%;
	background-color: #fff;
	color: #202020;
	border: 0;
	font-weight: 700;
}
.sidebar .ant-menu .ant-menu-item a {
	color: #202020;
	text-decoration: none;
}
.sidebar .ant-menu-vertical > .ant-menu-item {
	height: 60px;
	line-height: 60px;
	margin: 0;
	font-size: 16px;
	font-weight: bold;
}
.sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-active {
	background-color: #eff7ff;
}
.sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-active a {
	color: #36a3ff;
}
</style>
